<template>
	<div class="wrapper">
		<div class="back-button" @click="handleBack">
			<icon
				icon="v2ChevronLeft"
				class="back-button__chevron"
				colour="dark-navy"
			/>
			Back
		</div>
	</div>
</template>

<script>
	import Icon           from '@/components/ui/Icon';

	export default {
		components: { Icon },
		props: {
			route: {
				default: ''
			}
		},

		methods: {
			handleBack () {
        if (this.route) {
          this.$router.push(this.route);
        } else {
          this.$router.back();
        }
			}
		}
	};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  @include font($size: 16px, $color: $c-dark-navy, $weight: 800, $height: 20px);

  .back-button {
    display: flex;
    align-items: center;
    color: $c-dark-navy;
    cursor: pointer;

    &__chevron {
      padding: 5px 9px 6px 7px;
      width: 24px;
      height: 24px;
    }
  }
}

</style>

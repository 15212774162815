<template>
	<div class="page container">
		<v2-back-btn :route="backButtonLink" />
		<div class="course" v-if="course">
			<div class="course__header content">
				<div class="main">
					<div class="course__header--info">
						<div class="tag">
							<div class="signal" :class="[course.live ? 'live' : 'online']" />
							{{ course.live ? 'LIVE' : 'ONLINE' }}
						</div>
						<div class="tag">
							<icon icon="v2-book" colour="transparent" />
							{{ course && course.lessons && course.lessons.length || '' }} Lessons
						</div>
						<div class="tag">
							<icon icon="v2-shield" colour="transparent" />
							{{ getLevel }}
						</div>
					</div>
					<div class="course__header--title">
						{{ course.title }}
					</div>
					<div class="course__header--subtitle">
						{{ course.description }}
					</div>

					<div class="course__header--description">
						<div data-element="introduction" v-if="course.introduction">
							<div data-element="introduction" v-html="course.introduction" />
						</div>
					</div>

					<div class="course__header--controls">
						<V2Button v-if="getShowJoinBtn" :disabled="course.activated" @click="joinCourse">
							{{ course.activated ? 'Enrolled' : 'Join Course' }}
						</V2Button>
						<template v-if="!course.activated">
							<span
								class="course__header--controls__price"
								:class="{ discount: course.costWithDiscount, admin: !getShowJoinBtn && !course.costWithDiscount }"
							>
								{{ getCostString }}
							</span>
							<span
								v-if="course.costWithDiscount"
								class="course__header--controls__price"
								:class="{ admin: !getShowJoinBtn }"
							>{{ getDiscountString }}</span
							>
						</template>
						<v2-course-progress v-else-if="!course.live" :value="course.activityProgress || 0" />
					</div>
				</div>
				<div class="side">
					<img
						class="course__header--img"
						:src="getCoureImageUrl"
					/>
				</div>
			</div>
			<div class="separator" />
			<div class="course__body content">
				<div class="main">
					<div class="course__body--title">
						Lessons
					</div>
					<div class="course__body--lessons">
						<div class="create-lesson" v-if="getContext === 'teaching'">
							Create a new lesson to see it here
							<V2Button variant="outline" :to="`/teaching/courses/${course.id}/lessons/create`">
								Create lesson
							</V2Button>
						</div>
						<v2-lesson-card-small
							v-for="lesson in course.lessons"
							:lesson="lesson"
							:active="isLessonActive(lesson)"
							:key="lesson.id"
							:editMode="isEditMode"
							:adminMode="isAdminMode"
							:live="course.live"
						/>
					</div>
					<course-action-groups
						v-if="getCourseIsLoaded"
						:course="course"
					/>
				</div>
				<div class="side">
					<div class="course__body--info">
						<div class="course__body--info-title">
							<icon icon="v2-bookmark" colour="transparent" />
							Key Topics We’ll Cover
						</div>

						<div class="course__body--info-labels">
							<div class="tag" v-for="tag in course.keywords" :key="tag">
								{{ tag }}
							</div>
						</div>

						<div class="course__body--info-title">
							<icon icon="v2-teacher" colour="transparent" />
							Your Instructor
						</div>

						<div class="course__body--info-teacher teacher" @click="openTeacher">
							<img class="teacher__avatar" :src="getImageSrc" />
							<div class="teacher__text">
								<div class="teacher__text--title">
									{{ course.teacherName }}
								</div>
								<div class="teacher__text--subtitle">
									Teacher
								</div>
							</div>
						</div>

						<div v-if="isEditMode" class="course__body--info-title">
							<icon icon="v2-settings" colour="transparent" />
							Edit
						</div>

						<div v-if="isEditMode" class="course__body--info-controlls">
							<course-action-list
								v-if="getCourseIsLoaded"
								:course="course"
							/>
						</div>

					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import Icon                  from '@/components/ui/Icon';
	import CourseActionGroups    from '@/components/courses/CourseActionGroups';
	import CourseActionList      from '@/components/courses/CourseActionList';
	import V2CourseProgress      from '@/components/v2/courses/V2CourseProgress';
	import actionClick           from '@/mixins/actionClick';
	import routeParams           from '@/mixins/routeParams';
	import api                   from '@/services/api';
	import getStripe             from '@/services/stripe';
	import { courseCost, courseDiscountCost } from '@/formatters';
	import V2BackBtn from '@/components/v2/ui/V2BackBtn';
	import V2LessonCardSmall from '@/components/v2/lessons/V2LessonCardSmall';
	import V2Button from '@/components/v2/ui/V2Button';
	import MockImage from '@/assets/icons/v2/image-placeholder-small.svg';

	export default {
		components: {
			Icon,
			CourseActionGroups,
			CourseActionList,
			V2BackBtn,
			V2LessonCardSmall,
			V2CourseProgress,
			V2Button
		},
		mixins: [actionClick, routeParams],
		props: {
			course: {
				type: Object,
				default: () => ({})
			}
		},

		computed: {
			backButtonLink () {
        if (this.getContext === 'myCourses') {
          return '/my-courses';
        }

				return `/${this.getContext}/courses`;
			},

			isEditMode () {
				return this.getContext === 'teaching' || this.getContext === 'admin';
			},

			isAdminMode () {
				return this.getContext === 'admin';
			},

			getDiscountString () {
				return courseDiscountCost(this.course);
			},
			getLevel () {
        if (this.course.minExperienceLevel === this.course.maxExperienceLevel) {
          return this.course.minExperienceLevel;
        }

        return `${this.course.minExperienceLevel}-${this.course.maxExperienceLevel}`;
			},
			getIsLive () {
				return this.course.live;
			},
			getTeacherName () {
				return this.course?.teacherName;
			},
			getCourseTitle () {
				return this.course?.title;
			},
			getDescription () {
				return this.course?.description;
			},
			getIntroduction () {
				return this.course?.introduction;
			},
			getCostString () {
				if (this.getIsFree) {
					return 'free';
				}
				if (this.course?.costWithDiscount) {
					return courseDiscountCost(this.course);
				}
				return courseCost(this.course);
			},
			getIsFree () {
				return this.course.isFree;
			},
			getJoinBtnText () {
				if (this.getIsFree) {
					return 'Join now for free';
				}
				return `Buy now for ${this.getCostString}`;
			},
			getShowJoinBtn () {
				return this.getContext === 'browseCourses';
			},
			getCourseIsLoaded () {
				return this.course?.id !== undefined;
			},
			getTeacherProfileId () {
				return this.course?.teacherProfileId;
			},
			getTeacherProfileRoute () {
				if (!this.getTeacherProfileId) {
					return '';
				}
				return `/teachers/${this.getTeacherProfileId}`;
			},
			getLessons () {
				return this.course?.lessons;
			},
			getFocusString () {
				if (!this.course?.focus?.length) {
					return false;
				}
				return this.course.focus.join(', ');
			},
			getBiddingSystemsString () {
				if (this.course?.biddingSystems !== null &&
						!Array.isArray(this.course.biddingSystems)) {
					return false;
				}
				if (this.course.biddingSystems === null) {
					return 'None';
				}
				if (!this.course.biddingSystems?.length) {
					return 'Any';
				}
				return this.course.biddingSystems.join(', ');
			},
			getKeywords () {
				if (!this.course?.keywords?.length) {
					return false;
				}
				return this.course.keywords;
			},
			getAdditionalInfo () {
				return [
					(this.getBiddingSystemsString ?
						{
							heading: 'Systems',
							text: this.getBiddingSystemsString
						} : false
					),
					(this.getFocusString ?
						{
							heading: 'Focus',
							text: this.getFocusString
						} : false
					),
					(this.getKeywords ?
						{
							heading: 'Keywords',
							badges: this.getKeywords
						} : false
					)
				].filter(Boolean);
			},
			getLessonsWithSchedules () {
				return this.getLessons?.filter((lesson) => {
					return lesson?.schedule?.startsAt !== undefined &&
						lesson?.schedule?.startsAt !== null;
				});
			},
			getCompletedLessons () {
				return this.getLessons?.filter((lesson) => {
					return lesson?.schedule?.endedAt !== undefined &&
						lesson?.schedule?.endedAt !== null;
				});
			},
			getCourseIsComplete () {
				if (this.course?.live) {
					return this.course?.isComplete;
				}
				if (this.getContext !== 'myCourses') {
					return false;
				}
				return this.course.progress !== null;
			},

			getCoureImageUrl () {
        if (this.course?.media?.[0]?.url) {
          return this.course.media[0].url;
        }

				const focusTypes = {
					bidding: 'Bidding',
					'declarer play': 'Play',
					defence: 'Defence'
				};

				const focus = focusTypes[this.course?.focus?.[0]] || 'Other';

				return `/card-images/${focus}_Lev${this.course.maxExperienceLevel}.png`;
			},

			getImageSrc () {
				if (!this.course?.teacherProfile?.media?.[0]?.url) {
					return MockImage;
				}
				return this.course.teacherProfile.media[0].url;
			}
		},
		mounted () {
			this.init();
		},
		methods: {
			init () {
				if (this.$route.query?.completed) {
					this.$router.replace({ query: null });
					this.$store.commit('ui/showNotification', {
						notification: 'You can now view the hand records in Lesson resources.'
					});
				}
			},
			async joinCourse () {
				const course = await api.courses.joinCourseById({
					courseId: this.course.id
				});
				if (!course) {
					return false;
				}
				if (!course.checkout) {
					// no fee required, no stripe checkout
					this.$router.push(`/my-courses/${this.course.id}`);
					return;
				}
				const stripe = await getStripe();
				stripe.redirectToCheckout({
					sessionId: course.checkout
				});
			},

			openTeacher () {
				this.$router.push(`/teachers/${this.course.teacherProfileId}`);
			},

			isLessonActive (lesson) {
        if (!this.course?.lessons.length) {
          return false;
        }

        const activeLessonId = this.course.lessons.find((lsn) => {
          return lsn.activities.find((activity) => activity.progress === null);
        })?.id;

        return activeLessonId === lesson.id;
			}
		}
	};

</script>

<style lang="scss" scoped>
.course {
  &__header {
    margin-top: 24px;
    background: $c-white-l;
    padding: 20px;
    border-radius: 4px;

    @media (max-width: 1279px) {
      display: flex;
      flex-direction: column-reverse;

      .main,
      .side {
        width: 100%;
      }

      .side {
        img {
          width: 100%;
        }
      }
    }

    .main {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .side {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--info {
      display: flex;
      gap: 6px;
      margin-bottom: 8px;

      .tag {
        background: $c-gray-l;
      }
    }

    &--title {
      @include font(28px, $c-darkest-navy, 500, 36px);
    }

    &--subtitle {
      @include font(18px, $c-darkest-navy, 400, 23px);
    }

    &--description {
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      @include font(16px, $c-darkest-navy, 400, 20px);

      ::v-deep [data-element='introduction'] {
        font-size: rem(18);
        @include rich-text-content;
        @include rich-text-suits;
      }

      &-item {
        display: flex;
        align-items: center;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 13.5px;
          height: 13.5px;
          margin-right: 5.5px;
        }

        .bold {
          font-weight: 700;
        }
      }
    }

    &--controls {
      display: flex;
      align-items: center;
      margin-top: 8px;
      gap: 16px;

      .btn {
        @include font(20px, $c-white-l, 500, 26px);
        padding: 8px 20px;
        gap: 10px;
        border-radius: 4px;
        outline: inherit;
        background: $c-gray-d4;
        border: none;
      }

      &__price {
        @include font(20px, $c-dark-navy, 500, 26px);

        &.discount {
          text-decoration: line-through;
          opacity: 0.5;
        }

        &.admin {
          @include font(20px, $c-white-l, 500, 26px);
          padding: 8px 20px;
          gap: 10px;
          border-radius: 4px;
          outline: inherit;
          background: $c-dark-navy;
          border: none;
        }
      }

      .progress-wrapper {
        width: 105px;
      }
    }

    &--img {
      width: 277px;
      height: 174px;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  &__body {
    @media (max-width: 1279px) {
      display: flex;
      flex-direction: column;

      .main,
      .side {
        width: 100%;
      }
    }

    &--info {
      display: flex;
      flex-direction: column;

      &-title {
        display: flex;
        align-items: center;
        padding: 7.5px 0;
        margin-bottom: 12px;
        gap: 10px;
        @include font(18px, $c-darkest-navy, 700, 23px);
      }

      &-controlls {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .separator {
          margin: 6px 0;
        }

        .error {
          display: flex;
          gap: 6px;
          justify-content: space-around;
          @include font(14px, $c-medium-navy, 400, 20px);
        }
      }

      &-labels {
        margin-bottom: 36px;
        display: flex;
        flex-wrap: wrap;
        gap: 9px;

        .tag {
          background: $c-gray-l;
        }
      }

      &-teacher {
        display: flex;
        padding: 12px;
        border: 1px solid $c-gray-d2;
        gap: 12px;
        margin-bottom: 36px;
        align-items: center;
        cursor: pointer;

        .teacher {
          &__avatar {
            width: 45px;
            height: 45px;
            object-fit: cover;
            border-radius: 2px;
          }

          &__text {
            display: flex;
            flex-direction: column;
            gap: 4px;

            &--title {
              @include font(18px, $c-dark-navy, 500, 23px);
            }

            &--subtitle {
              @include font(14px, $c-medium-navy, 700, 18px);

              ::v-deep [data-element='introduction'] {
                font-size: rem(18);
                @include rich-text-content;
                @include rich-text-suits;
              }
            }
          }
        }
      }
    }

    &--title {
      @include font(24px, $c-darkest-navy, 500, 31px);
      margin-bottom: 36px;
    }

    &--lessons {
      margin-bottom: 36px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 12px;

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
      }

      .create-lesson {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
				padding: 40px 36px 20px 36px;
        border: 1px dashed $c-gray-d;
        border-radius: 4px;
        text-align: center;
        @include font(18px, $c-medium-navy, 400, 23px);

        .btn {
          margin-top: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 20px;
          border: 1px solid $c-dark-navy;
          border-radius: 4px;
          @include font(16px, $c-dark-navy, 500, 20px);
        }
      }
    }

    &--lessons-full {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
    }
  }
}

	[data-component='course-view'] {
		@include nested-grid;
		article {
			@include nested-cols(12, 12);
			margin-bottom:rem(64);
			@include mq('sm') {
				@include nested-cols(7, 12);
				@include nested-gut(12);
				margin-bottom:0;
			}
			[data-element='type-and-level'] {
				display:flex;
				margin:0 0 rem(42);
				&:last-child {
					margin-bottom:0;
				}
				[data-component='live-or-solo-badge'] {
					margin:0 rem(32) 0 0;
				}
				[data-component='experience-level-chart'] {
					margin:0 auto 0 0;
				}
			}
			[data-element='teacher'] {
				display:flex;
				align-items:center;
				margin:0 0 rem(8);
				&:last-child {
					margin-bottom:0;
				}
				&:hover {
					text-decoration:underline;
				}
				[data-component='icon'] {
					width:auto;
					height:rem(38);
					margin:0 rem(4) 0 0;
				}
				span {
					font-family:'Montserrat', sans-serif;
					font-size:rem(14);
					font-weight:700;
					color:$c-brand-grey;
				}
			}
			[data-element='heading'] {
				margin:0 0 rem(8);
				font-family:'Montserrat', sans-serif;
				font-size:rem(34);
				font-weight:700;
				color:$c-brand-blue;
				&:last-child {
					margin-bottom:0;
				}
			}
			[data-element='subheading'] {
				margin: 0 0 rem(58);
				font-size:rem(18);
				color:$c-brand-blue;
				&:last-child {
					margin-bottom:0;
				}
			}
			[data-element='introduction'] {
				margin:0 0 rem(38);
				&:last-child {
					margin-bottom:0;
				}
				h2 {
					margin-bottom:rem(12);
					font-family:'Montserrat', sans-serif;
					font-size:rem(18);
					font-weight:700;
				}
				::v-deep [data-element='introduction'] {
					font-size:rem(18);
					@include rich-text-content;
					@include rich-text-suits;
				}
			}
			[data-element='main-cta'] {
				display:flex;
				margin:0 0 rem(60);
				&:last-child {
					margin-bottom:0;
				}
				[data-component='btn'] {}
			}
			[data-component='lesson-list'] {
				margin-bottom:rem(64);
				&:last-child {
					margin-bottom:0;
				}
			}
			[data-component='course-action-groups'] {
			}
		}
		aside {
			@include nested-cols(12, 12);
			@include mq('sm') {
				@include nested-cols(4, 12);
				@include nested-push(1, 12);
			}
			[data-component='additional-info-list'] {
				margin-bottom:rem(22);
				&:last-child {
					margin-bottom:0;
				}
			}
			[data-component='course-action-list'] {
				margin-bottom:rem(26);
			}
			[data-element='badges'] {
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				margin-bottom:rem(24);
				&:last-child {
					margin-bottom:0;
				}
			}
		}
	}

</style>
